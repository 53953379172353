import React from "react"
import theme from "theme"
import { Theme, Text, Icon, Link, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { BsPhone, BsEnvelope, BsMap } from "react-icons/bs"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contacts"} />
      <Helmet>
        <title>The Trim Trend</title>
        <meta name={"description"} content={"Where Style Meets Tradition"} />
        <meta property={"og:title"} content={"The Trim Trend"} />
        <meta
          property={"og:description"}
          content={"Where Style Meets Tradition"}
        />
        <meta
          property={"og:image"}
          content={"https://zivoliteplay.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://zivoliteplay.com/img/barber.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://zivoliteplay.com/img/barber.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://zivoliteplay.com/img/barber.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://zivoliteplay.com/img/barber.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://zivoliteplay.com/img/barber.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://zivoliteplay.com/img/barber.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 90px 0"
        quarkly-title="Contacts-5"
        background="--color-light"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          min-height="80vh"
        />
        <Text
          lg-padding="0px 0px 0px 0px"
          sm-font="normal 700 36px/1.2 --fontFamily-sans"
          margin="0px 0px 18px 0px"
          font="normal 600 42px/1.2 --fontFamily-sans"
          color="--primary"
          text-align="center"
          padding="0px 0 0px 0px"
          lg-width="100%"
          lg-margin="0px 0px 25px 0px"
        >
          Contact Us
        </Text>
        <Text
          font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
          lg-margin="0px 0 50px 0px"
          text-align="center"
          sm-margin="0px 0 35px 0px"
          margin="16px 0 50px 0px"
          color="#60666d"
          width="700px"
          md-width="auto"
        >
          Visit us today for a trim that’s as stylish as you are! For bookings
          or inquiries:
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="16px 24px"
          margin="0px 0px 80px 0px"
          md-margin="0px 0px 50px 0px"
          lg-grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="1fr"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
            padding="36px 20px 20px 20px"
            border-width="1px"
            border-style="solid"
            border-color="--color-lightD2"
            border-radius="4px"
          >
            <Icon
              category="bs"
              icon={BsPhone}
              size="36px"
              margin="0px 0px 20px 0px"
              color="--primary"
            />
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
              text-align="center"
              color="--primary"
              md-margin="0px 0px 20px 0px"
            >
              Phone Number
            </Text>
            <Link
              href="tel:+61 747 799 900"
              color="#47474d"
              text-decoration-line="initial"
              font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
              display="block"
              text-align="center"
              margin="0px 0px 15px 0px"
            >
              +61 747 799 900
            </Link>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
            padding="36px 20px 20px 20px"
            border-width="1px"
            border-style="solid"
            border-color="--color-lightD2"
            border-radius="4px"
          >
            <Icon
              category="bs"
              icon={BsEnvelope}
              size="36px"
              margin="0px 0px 20px 0px"
              color="--primary"
            />
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
              text-align="center"
              color="--primary"
              md-margin="0px 0px 20px 0px"
            >
              Email
            </Text>
            <Link
              href="mailto:about@zivoliteplay.com"
              color="#47474d"
              text-decoration-line="initial"
              font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
              display="block"
              margin="0px 0px 15px 0px"
              text-align="center"
            >
              about@zivoliteplay.com
            </Link>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
            padding="36px 20px 20px 20px"
            border-width="1px"
            border-style="solid"
            border-color="--color-lightD2"
            border-radius="4px"
          >
            <Icon
              category="bs"
              icon={BsMap}
              size="36px"
              margin="0px 0px 20px 0px"
              color="--primary"
            />
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
              text-align="center"
              color="--primary"
              md-margin="0px 0px 20px 0px"
            >
              Location
            </Text>
            <Text
              margin="0px 0px 20px 0px"
              color="#47474d"
              font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
              text-align="center"
              border-color="#b8acac"
              md-margin="0px 0px 15px 0px"
            >
              22 Fleming St, Aitkenvale QLD 4814
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
